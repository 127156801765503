<template>
  <div
    class="disclaimer"
  >
    <span class="title">{{ t('title') }}</span>
    {{ t('subtitle', { company: companyName }) }}
    <ZTooltipIcon
      :content="t('tooltip', { insurance: insuranceName, amount: insuranceAmount })"
      :title="t('title')"
    />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { priceDisplay } = usePrice()
const { rvIsFromCanada, rvCountry, rvLiabilityAmount } = useRvDetails()

const insuranceName = computed(() => {
  return rvIsFromCanada.value ? t('canadaCompanies') : t('usCompanies')
})

const companyName = computed(() => {
  return rvIsFromCanada.value ? 'Liberty Insurance' : 'Mobilitas Insurance'
})

const insuranceAmount = computed(() => {
  return priceDisplay({
    value: rvLiabilityAmount.value,
    countryCode: rvCountry.value,
    round: true,
  })
})
</script>

<style lang="scss" scoped>
.disclaimer {
  color: getColor('primary-350');
  @include caption;

  .title {
    display: block;
    @include caption-strong;
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "title": "Insurance and protection",
    "subtitle": "Insurance provided by {company}.",
    "tooltip": "All plans include roadside assistance and {amount} in liability coverage provided by {insurance}.",
    "canadaCompanies": "Liberty Insurance, or ICBC in British Columbia",
    "usCompanies": "Mobilitas Insurance, or Apollo"
  },
  "fr": {
    "title": "Assurance et protection",
    "subtitle": "Assurance fournie par {company}",
    "tooltip": "Tous les plans incluent une assistance routière et une couverture de responsabilité de {amount} fournie par {insurance}.",
    "canadaCompanies": "Liberty Insurance, ou bien ICBC en Colombie-Britannique",
    "usCompanies": "Mobilitas Insurance ou Apollo"
  }
}
</i18n>
